import Layout from "./common/layout"
import Store from "./service/Store/Store"
import Head from "./common/Head"
import { useFormatter } from "helpers/i18n"
import banner from "images/service/store/service-2-banner.png"

const ServiceStore = () => {
  const { currentLocale } = useFormatter()

  return (
    <>
      <Head
        showCaptcha={true}
        title={
          currentLocale === "en"
            ? "E-commerce store management with extensive experience"
            : "Quản lý gian hàng trên các sàn thương mại điện tử"
        }
        description={
          currentLocale === "en"
            ? "OnPoint excels at managing online stores on marketplace platforms to generate leads, ensure conversion rate and increase revenue."
            : "OnPoint có kinh nghiệm quản lý gian hàng trên các sàn thương mại điện tử để thu hút người mua tiềm năng, đảm bảo tỷ lệ chuyển đổi, tăng doanh số bán hàng trực tuyến."
        }
        image={
          "https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/service-2-meta.png"
        }
      />
      <Layout>
        <Store />
      </Layout>
    </>
  )
}

export default ServiceStore
